import { ISepaBody, ISepaResponseBody } from "../../store/slices/sepa";
import { adminInstance, userInstance } from "../api";

const create = (data: ISepaBody) => {
  return userInstance.post("/sepa", data).then((res) => res.data);
};

const getAdminSepaData = ({
  signal,
  direction,
  endDate,
  page,
  pageSize,
  payerType,
  searchValue,
  sort,
  startDate,
  status,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
  payerType?: string[];
}) => {
  return adminInstance
    .get<{ data: ISepaResponseBody[]; totalCount: number }>("/admin/sepa", {
      params: {
        direction,
        endDate,
        page,
        pageSize,
        payerType,
        searchValue,
        sort,
        startDate,
        status,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminSepaItemById = (id: string) => {
  return adminInstance
    .get<ISepaResponseBody>(`/admin/sepa/${id}`)
    .then((res) => res.data);
};

const updateStatusById = ({ id, status }: { id: string; status: string }) => {
  return adminInstance
    .put<ISepaResponseBody>(`/admin/sepa/${id}/status`, {
      status,
    })
    .then((res) => res.data);
};

const downloadExcel = ({
  searchValue,
  status,
  endDate,
  startDate,
  payerType,
}: {
  searchValue?: string;
  status?: string[];
  payerType?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  return adminInstance
    .get(`/admin/excel/sepa`, {
      responseType: "blob",
      params: {
        searchValue,
        status,
        endDate,
        startDate,
        payerType,
      },
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Sepa.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const Sepa = {
  get: {
    getAdminSepaData,
    getAdminSepaItemById,
    downloadExcel,
  },
  post: {
    create,
  },
  put: {
    updateStatusById,
  },
};

export default Sepa;
